import React, { useState } from 'react';
import { Modal, Button , ModalHeader} from 'react-bootstrap';
import { ApolloProvider, ApolloClient, InMemoryCache, gql, useMutation } from "@apollo/client";
import ContactFromDate from "data/sections/form-info.json";

const JobModal=({oppturtunityData,...props})=> {

  const APOLLO_CLIENT = new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    cache: new InMemoryCache()
  });

  const FormData =()=>{

    const [fullNameValue,setFullNameValue]=useState("");
    const [emailValue,setEmailValue]=useState("");
    const [contactValue,setContactValue]=useState("");
    const [expValue,setExpValue] = useState("");
    const [currentDesignation, setCurrentDesignation] = useState("");
    const [currentLocation, setCurrentLocation ] = useState("");


    const CREATE_JOB_APPLICATION = gql`
    mutation CreatejobApplicationMutation($clientMutationId: String!, $fullName:String!, $email: String!, $contact: String!, $exp: String!, $current_Dsg: String!, $current_Loc: String!) {
      createJobapplication(input: {clientMutationId: $clientMutationId, fullName: $fullName, email: $email, contact: $contact, exp: $exp, current_Dsg: $current_Dsg, current_Loc: $current_Loc}) {
        success
        data
      }
    }
  `;

    // Use useMutation hook to execute the mutation
    const [createJobApplication, { data, loading, error }] = useMutation(CREATE_JOB_APPLICATION);
    
    // Function to handle form submission
    const handleSubmit = async (event)=>{
      event.preventDefault(); // Prevent default form submission behavior

      try{
        const result=await createJobApplication({
      variables:{
        clientMutationId: "abc123",
        fullName: fullNameValue,
        email: emailValue,
        contact: contactValue,
        exp: expValue,
        current_Dsg: currentDesignation,
        current_Loc: currentLocation
      }
    });
      console.log(result.data.createJobApplication);
    }catch(error){
      console.error("Error creating job application",error)
    }
    };

    ModalHeader

    return (
      <>
      {oppturtunityData && oppturtunityData.length > 0 && (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='job-form' onSubmit={handleSubmit}>
            <div className="form-field">
              <input type="text" name="fullname" id="fullname" required="required" onChange={(event)=>setFullNameValue(event.target.value)}/>
              <label htmlFor="fullname">Full Name</label>  
                <br />
            </div>
              
            <div className="form-field">
              <input id="email" type="email" name="email" required="required" onChange={(event)=>setEmailValue(event.target.value)} />
              <label htmlFor="email">Email</label>
                <br />
            </div>  
              
            <div className="form-field">
              <input type="tel" name="contact" id="contact" required="required" onChange={(event)=>setContactValue(event.target.value)}/>
              <label htmlFor="contact">Phone</label>
                <br />
            </div>

            <div className="exp form-field">
              <select value={expValue} required="required" onChange={(event)=>setExpValue(event.target.value)}>
                  <option disabled value="">Select Experience</option>
                  <option select value="0-6 months">0-6 months</option>
                  <option value="6 months-1 year">6 months-1 year</option>
                  <option value="1-2 years">1-2 years</option>
                  <option value="2-3 years">2-3 years</option>
                  <option value="3+ years">3+ years</option>
                </select>
            </div>  
              
            <div className="form-field">
              <input type="text" name="current_Dsg" id="current_Dsg" required="required" onChange={(event)=>setCurrentDesignation(event.target.value)}/>
              <label htmlFor="current_Dsg">Current Designation</label>  
                {/* <br /> */}
            </div>

            <div className="form-field">
              <input type="text" name="current_Loc" id='current_Loc' required="required" onChange={(event)=>setCurrentLocation(event.target.value)}/>
              <label htmlFor="current_Loc">Current Location</label>
                <br />
            </div>
 
              <br />


              {loading && <p>Loading...</p>}
                {error && (<p>An unknown error has occured, please try again later ...</p>)}
                {data && data.createJobapplication.success && <p>Message sent successfully!</p>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='  btn btn-success' type="submit">
            <span>Send Message</span>
          </button>
          <Button className='btn btn-danger' onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
      )}
      </>
    );
      }

      return (
        <ApolloProvider client={APOLLO_CLIENT}>
          <FormData />
        </ApolloProvider>
      );

  }

  export default JobModal;